<template>
    <section>
      <LogoLoading v-if="!productDetail"/>
      <div v-else class="oliveOil-detail">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10 col-12 mt-5">
              <div class="name" v-html="processedName"></div>
              <div class="setores">
                <div class="setor">
                  <div class="oliveOil-text-desc mb-3" v-html="productDetail.description"/>
                  <div v-if="showYear" class="setor-year">{{ productDetail.year }} - {{$t("yearSufix").toUpperCase() }}</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-10 col-12 image-col mt-5">
              <div class="product position-relative d-flex justify-content-center">
                <img loading=lazy class="img-fluid w-65 img-hover" :src="joinPaths(productDetail.photoPath)" alt="" />
              </div>
            </div>
          </div>
        </div>
  
        <!--specs -->
        <div class="prizes-data"  v-if="productDetail.mainProductInfos.length != 0">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div v-if="productDetail.prizes.length" class="col-12 col-lg-4 prizes">
                <div class="setor-title">{{$t("premios.title").toUpperCase() }}</div>
                <div class="oliveOil-text">
                  <div class="oliveOil-text prize" v-for="prize in productDetail.prizes" :key="prize.id">
                    {{ prize.year }} - {{ prize.title }}
                  </div>
                </div>
              </div>
              <div class="col-12 specs">
                <div class="row data-info justify-content-center">
                  <div class="col-12 col-lg data-item" v-for="(keyValue, index) in productDetail.mainProductInfos" :key="index">
                    <!-- <img loading=lazy src="@/assets/svg/grapes.svg" class="mb-3" alt=""> -->
                    <div class="setor-title keyValue-keys text-center">{{keyValue.keys.pt}}</div>
                    <div class="oliveOil-text keyValue-values text-center text-break">{{ keyValue.values.pt }}</div>
                  </div>
                  <div v-if="productDetail.datasheet != null" class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/info.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("oliveOil.datasheet")}}</div>
                    <div class="oliveOil-text text-center">
                      <a
                        :href="technicalFilePath"
                        target="_blank"
                        rel="noopener"
                        >{{$t("oliveOil.download")}}</a
                      >
                    </div>
                  </div>
                  <div v-if="productDetail.nutritionalSheet != null" class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/info.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("oliveOil.nutritionalSheet")}}</div>
                    <div class="oliveOil-text text-center">
                      <a
                        :href="nutritionalFilePath"
                        target="_blank"
                        rel="noopener"
                        >{{$t("oliveOil.check")}}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <OliveOil :title="titleType" :type="type" class="mt-5 mb-5" /> -->
      </div>
    </section>
  </template>
  <script>
  import router from "@/router";
  // import OliveOil from "@/components/HomePage/oliveOil/OliveOil.vue";
  import { mapState, mapActions } from 'vuex';
  import { API_HELPER } from "@/helpers/api.js";
  import LogoLoading from "@/components/LogoLoading.vue"
  import { UTILS_HELPER } from "@/helpers/utils.js";
  
  export default {
    props: {
      showYear: {
        type: Boolean
      }
    },
    metaInfo () {
      var result =
      { 
          title: this.processedName ? `${this.processedName} - Cooperativa de Freixo de Numão` : "Cooperativa de Freixo de Numão", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.productDetail && this.productDetail.description ? UTILS_HELPER.htmlToText(this.productDetail.description) : this.$t("intro")},
              {name: 'keywords', content: this.productDetail && this.processedName && this.productDetail.type ? this.processedName + ", " + this.productDetail.type : this.$t("vueRoutes.defaultKeywords")},
                // Twitter Card
              // {name: 'twitter:card', content: "summary"},
              // {name: 'twitter:title', content: this.productDetail ? `${this.productDetail.name} - Cooperativa de Freixo de Numão` : "Cooperativa de Freixo de Numão"},
              // {name: 'twitter:description', content: this.productDetail && this.productDetail.description ? UTILS_HELPER.htmlToText(this.productDetail.description) : this.$t("intro")},
              // //Facebook OpenGraph
              // {property: 'og:title', content: this.productDetail ? `${this.productDetail.property} - Cooperativa de Freixo de Numão` : "Cooperativa de Freixo de Numão"},
              // {property: 'og:site_name', content: 'Cooperativa de Freixo de Numão'},
              // {property: 'og:type', content: 'website'},
              // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
              // {property: 'og:description', content: this.productDetail && this.productDetail.description ? UTILS_HELPER.htmlToText(this.productDetail.description) : this.$t("intro")},
          ]
      } 
  
      if(this.$route.params.oliveOilSlug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.oliveOilSlug])}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.oliveOilSlug])}`},
          ];
      }
      return result;
    }, 
    components: { LogoLoading },
    computed: {
      ...mapState({ 
        productDetailOutput: state => state.products.detailOutput,
      }),
      technicalFilePath: function()
      {
        return this.productDetail.datasheet ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.productDetail.datasheet]) : null;
      },
      nutritionalFilePath: function()
      {
        return this.productDetail.nutritionalSheet ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.productDetail.nutritionalSheet]) : null;
      },
      productDetail(){
        return this.productDetailOutput && this.productDetailOutput.data ? this.productDetailOutput.data : null;
      },
      type(){
        return this.productDetail && this.productDetail.types ? this.productDetail.types[0] : null;
      },
      titleType(){
        var title;
  
        if(this.type == "White"){
          title = this.$t("type.white");
        }
        else if(this.type == "Red"){
          title = this.$t("type.red");
        }
        else{
          title = this.$t("type.reserve");
        }
  
        return title;
      },
      // featuredImage(){
      //   return this.productDetail && this.productDetail.photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.productDetail.photoPath]) : null;
      // },
      processedName() {
        var result = this.productDetail ? this.productDetail.name : '';
        result = result.replace(/<\/?[^>]+(>|$)/g, ""); // Remove HTML tags
        result = result.replace(/(.*?\s.*?\s)/g, '$1' + '</br>');
        return result;
      }
    },
    methods: {
      ...mapActions('products', ['getDetails']),
      initializePage() {
        if (!this.$route.params.oliveOilSlug) {
          router.push({ name: "OliveOil" });
        }
        else{
          if (this.$route.params.oliveOilSlug) {
            this.getDetails(this.$route.params.oliveOilSlug);
          }
        }
      },
      joinPaths(relativePath){
        return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
      }
    },
    mounted() {
      this.initializePage();
    },
    watch:
    {
      $route(to) {
        if(to.name == "OliveOilDetail")
        {
          this.$router.go(0);
        }
      },
      productDetailOutput: function(val)
      {
        if(val == false)
        {
          router.push({ name: "OliveOil" })
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .oliveOil-detail {
    padding-top: 170px;
  }
  .w-65{
    width: 65%;
  }
  .img-hover{
    max-height: 675px;
    object-fit: contain;
    transition: transform .2s;
  }
  .img-hover:hover {
    transform: scale(1.2);
  }
  .category {
    margin-top: 74px;
  }
  .oliveOil-detail .name {
    font-size: 52px;
    line-height: 75px;
    margin-bottom: 40px;
    color: var(--primary-color);
    text-align: left;
    font-weight: bold;
  }
  .oliveOil-detail .setor {
    margin-bottom: 44px;
    max-width: 550px;
  }
  .setor-title,
  .setor-year {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--secondary-color);
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
  
  }
  .oliveOil-text-desc {
    font-size: 35px;
    line-height: 40px;
    color: var(--secondary-color);
    text-align: left;
    overflow-wrap: break-word;
  }
  .oliveOil-text {
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-color);
    text-align: left;
  }
  .keyValue-keys {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--secondary-color);
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
  
  }
  .keyValue-values {
    font-size: 14px;
    line-height: 24px;
    color: var(--primary-color);
    text-align: left;
  }
  
  .data-item img {
    height: 50px;
  }
  
  /* prizes-data */
  .prizes-data {
    position: relative!important;
    padding-top: 64px;
    /* padding-bottom: 16px; */
  }
  .prizes-data .oliveOil-text {
      line-height: 22px;
  }
  .prizes-data .prizes{
    background-color: var(--primary-color);
    height: auto;
    padding-top: 55px;
    padding-bottom: 40px;
  }
  .prizes-data .specs{
    background-color: rgba(248,238,226,.24);
    height: auto;
    padding-top: 55px;
    padding-bottom: 40px;
  }
  .oliveOil-text a{
      color: inherit;
  }
  .product .logo{
    width: auto;
    height: 90px;
    position: absolute;
    bottom: 15%;
    right: 0;
  }
  .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  @media screen and (min-width: 992px) {
    .col-xl-2{
      width: 19.667%;
    }
  }
  @media screen and (max-width: 991px) {
    .product {
      justify-content: center !important;
    }
    .prizes-data .data-item {
      margin-bottom: 32px;
    }
    .prizes-data {
      padding-top: 0;
      padding-bottom: 0;
      background: none;
    }
    .prizes-data .setor-title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 1;
      right: 0;
      height: 14%;
      width: 100%;
      z-index: -1;
    }
    .prizes-data .data-info {
      padding-bottom: 22px;
    }
    .prizes-data .data-info::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .img-hover:hover {
      transform: none;
    }
  }
  @media screen and (max-width: 768px) {
    .product .logo{
      position: unset;
      margin: 30px auto;
    }
  }
  @media screen and (max-width: 576px) {
    .oliveOil-text-desc {
      font-size: 25px;
    }
  }
  </style>
  