<template>
  <div class="footer">
    <div class="container">
      <div class="copyrights mt-2 mt-lg-0">
        <div class="row">
          <div class="col-12 col-md-6 d-flex flex-row gap-3 mobile-style">
            <div class="d-flex flex-row gap-2">
                <a :href="getSettings.facebook" class="clean-link" target="_blank">
                  <b-icon class="icons-style" icon="facebook"></b-icon>
                </a>
                <!-- <a href="https://www.instagram.com" class="clean-link" target="_blank">
                  <b-icon class="icons-style" icon="instagram"></b-icon>
                </a> -->
            </div>
            <div class="d-flex flex-column align-items-start gap-2">
              <a class="clean-link text-bold" :href="`mailto:${getSettings.email}`">
                {{ getSettings.email }}
              </a>
              <a class="clean-link text-bold text-start" :href="`tel:${this.getSettings.phone}`">{{
                getSettings.phone
              }}</a>
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex flex-column gap-2 align-items-end justify-content-start mobile-style">
            <router-link class="clean-link" active-class="active" exact :to="{ name: 'StaticInfos', params: { slug: 'legal' } }">{{ $t("legalInformation") }}</router-link>
            <router-link class="clean-link" active-class="active" exact :to="{ name: 'StaticInfos', params: { slug: 'qualidade' } }">{{ $t("qualityPolicy") }}</router-link>
            <router-link class="clean-link" active-class="active" exact :to="{ name: 'StaticInfos', params: { slug: 'privacy' } }">{{ $t("privacy") }}</router-link>
            <router-link class="clean-link" active-class="active" exact :to="{ name: 'StaticInfos', params: { slug: 'cookies' } }">{{ $t("cookies") }}</router-link>
          </div>
          <div class="col-12 d-flex justify-content-start mt-5 mobile-style">
            <a href="https://www.adarme.pt/" class="clean-link poweredBy" target="_blank">POWERED BY © ADARME.PT</a><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Newsletter from '@/components/Newsletter.vue';
import { mapActions, mapState } from "vuex";

export default {
  components: {
    // Newsletter
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState("settings", ["settingsOutput"]),
    getSettings() {
      return this.settingsOutput && this.settingsOutput.data
        ? this.settingsOutput.data
        : null;
    },
    // settingsStored: function(){
    //   return JSON.parse(localStorage.settings);
    // },
  },
  methods: {
    ...mapActions("settings", ["getList"]),
  },
};
</script>

<style scoped>
.footer {
  background-color: var(--secondary-color);
  padding: 3rem 0;
}

.footer .contact {
  color: white;
  font-size: 16px;
  line-height: 30px;
}

.clean-link {
  color: inherit;
  text-decoration: none;
}

.copyrights {
  padding-top: 15px;
  padding-bottom: 15px;
}

.copyrights a {
  color: white;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
}
.icons-style {
  width: 50px;
  height: auto;
}
#prizes {
  max-height: 100px;
}
.poweredBy{
  font-size: 12px !important;
}

@media only screen and (min-width: 768px) {
  #prizes-container {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 767px) {
  #prizes-container {
    justify-content: center;
  }
  .mobile-style {
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 1rem;
  }
}
</style>